import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ConnectorNames } from 'orbui'
import Web3 from 'web3'
import getNodeUrl from './getRpcUrl'
import { BaseConnector } from './baseConnector'

const POLLING_INTERVAL = 12000
const rpcUrl = "https://rpc.blast.io/"
const chainId = 81457

const injected = new InjectedConnector({ supportedChainIds: [chainId] })

const walletconnect = new WalletConnectConnector({
  rpc: { [chainId]: rpcUrl },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

const base = new BaseConnector({ supportedChainIds: [chainId] })

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: undefined
}

export const getLibrary = (provider): Web3 => {
  return provider
}
