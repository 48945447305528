import { MenuEntry } from 'orbui'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    href: 'https://exchange.orbeswap.com',
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
]

export default config
