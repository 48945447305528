export default {
  orbe: {
    420: '0x1c28420F79ebb92Fdf684337D635FB59C24C064c', // CHANGELA
    81457: '0xf726b769EA45eDF14d159b23B2bDa1a1af98f0B2',
  },
  token1: {
    420: '0x593401484a8D63992C0EdcC5438E833B53F1BaB0',
    81457: '0x593401484a8D63992C0EdcC5438E833B53F1BaB0',
  },
  wbnb: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  busd: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
  fuel: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A',
  },
  fts: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x4437743ac02957068995c48E08465E0EE1769fBE',
  },
  usdc: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
  },
  dai: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  },
  usdt: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x55d398326f99059fF775485246999027B3197955',
  },
  btcb: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  },
  eth: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  bhc: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
  },
  tryon: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x050f65BEf6bd15De57462cf75d06BafEB2A59118',
  },
  gfcev1: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x94BaBBE728D9411612Ee41b20241a6FA251b26Ce',
  },
  gfcev2: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x5f136383E230F972739FaE2E81E7E774AfE64c66',
  },
  masterChef: {
    420: '0xcdC5fe5f454dF1a41EE755BE34d56ABd790Dc699',
    81457: '0xcdC5fe5f454dF1a41EE755BE34d56ABd790Dc699',
  },
  pancakeMasterChef: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  apeswapMasterChef: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x5c8D727b265DBAfaba67E050f2f739cAeEB4A6F9',
  },
  autoFarmMasterChef: {
    420: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    81457: '0x0895196562c7868c5be92459fae7f877ed450452',
  },
  fortressIUnitroller: {
    420: '',
    81457: '0x67340Bd16ee5649A37015138B3393Eb5ad17c195',
  },
  venusIUnitroller: {
    420: '',
    81457: '0xfD36E2c2a6789Db23113685031d7F16329158384',
  },
  sousChef: {
    420: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    81457: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  mulltiCall: {
    81457: '0x0304E67C8ee6dF58c378CAdb657c572891929017',
    420: '0x0304E67C8ee6dF58c378CAdb657c572891929017',
  },
  pancakeProfile: {
    81457: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    420: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    81457: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    420: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    81457: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    420: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    81457: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    420: '',
  },
  bunnySpecial: {
    81457: '0xFee8A195570a18461146F401d6033f5ab3380849',
    420: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  dryonPresale: {
    81457: '0xFc432aA24ddb0F29133B32b0D1cf9084615Ea1db',
    420: '',
  },
  wingsBnbPair: {
    81457: '0x1CD0fe829d83Fb49c8831Ae860d19c6062adA6e9',
    420: '',
  },
  wingsBusdPair: {
    81457: '0xFBa740304f3fc39d0e79703a5D7788E13f877DC0',
    420: '',
  },
  hpsBnbPair: {
    81457: '0x918726Fd1C8Fb80E7C9A01a75DD057F0DE3457eb',
    420: '',
  },
  presale: {
    81457: '0xD3b01bC0A6c8F31a40E37D031ff407F4E2ad1115',
    420: '0x96d67317a70D0D1Bc2Ab0e185131A205a57188DA',
  },
  claim: {
    81457: '0xd63aA38E162a3f326d89A60482F82ec8af0abd59',
    420: '0xd63aA38E162a3f326d89A60482F82ec8af0abd59',
  },
}
