import styled from 'styled-components'
import Container from './Container'

const Page = styled(Container)`
  width: 100%;
  min-height: calc(100vh - 64px);
  margin-top: 16px;
  margin-bottom: 16px;
`

export default Page
