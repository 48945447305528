import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 1,
    lpSymbol: 'ORBE-wETH LP', // todo
    lpAddresses: {
      420: '0x02Bd45F0c4053C3bbb16ae8f2189789E8a46f317',
      81457: '0x02Bd45F0c4053C3bbb16ae8f2189789E8a46f317',
    },
    token: tokens.weth,
    quoteToken: tokens.orbe,
  },
]

export default farms
