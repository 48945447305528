import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { poolsConfig } from 'config/constants'
import { PoolCategory, Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const mainNetChainId = 81457
  const chainId = 81457
  return address[chainId] ? address[chainId] : address[mainNetChainId]
}

export const getOrbeAddress = () => {
  return getAddress(tokens.orbe.address)
}
export const getCakeAddress = () => {
  return getAddress(tokens.cake.address)
}
export const getWingsBnbPairAddress = () => {
  return getAddress(addresses.wingsBnbPair)
}
export const getWingsBusdPairAddress = () => {
  return getAddress(addresses.wingsBusdPair)
}
export const getDryonPresaleAddress = () => {
  return getAddress(addresses.dryonPresale)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getPancakeMasterChefAddress = () => {
  return getAddress(addresses.pancakeMasterChef)
}
export const getApeswapMasterChefAddress = () => {
  return getAddress(addresses.apeswapMasterChef)
}
export const getAutoFarmMasterChefAddress = () => {
  return getAddress(addresses.autoFarmMasterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.mulltiCall)
}
export const getWethAddress = () => {
  return getAddress(tokens.wbnb.address)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getHpsBnbPairAddress = () => {
  return getAddress(addresses.hpsBnbPair)
}
export const getFuelAddress = () => {
  return getAddress(tokens.fuel.address)
}
export const getGfceV1Address = () => {
  return getAddress(addresses.gfcev1)
}
export const getGfceV2Address = () => {
  return getAddress(addresses.gfcev2)
}
export const getPresaleAddress = () => {
  return getAddress(addresses.presale)
}
export const getClaimAddress = () => {
  return getAddress(addresses.claim)
}
export const getTkn1Address = () => {
  return getAddress(addresses.token1)
}
export const getUSDCAddress = () => {
  return getAddress(addresses.usdc)
}
