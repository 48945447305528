/* eslint-disable */
import React, { useEffect, useRef } from 'react'
import * as PIXI from 'pixi.js'
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur'
import { createNoise2D } from 'simplex-noise'
import hsl from 'hsl-to-hex'
import debounce from 'debounce'

const Background = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (canvasRef.current != null) {
      // Create a new PIXI Application
      const canvas = canvasRef.current
      const app = new PIXI.Application({
        view: canvas,
        resizeTo: window,
        transparent: true,
      })

      const noise2D = createNoise2D()

      // Orb class
      class Orb {
        // Pixi takes hex colors as hexadecimal literals (0x rather than a string with '#')
        private readonly fill: number

        private bounds: { x: { min: number; max: number }; y: { min: number; max: number } }

        private x: number

        private y: number

        private scale: number

        private readonly radius: number

        private xOff: number

        private yOff: number

        private readonly inc: number

        public readonly graphics: PIXI.Graphics

        constructor(fill = 0x000000) {
          this.fill = fill
          this.bounds = this.setBounds()
          this.x = this.random(this.bounds.x.min, this.bounds.x.max)
          this.y = this.random(this.bounds.y.min, this.bounds.y.max)
          this.scale = 1
          this.radius = this.random(window.innerHeight / 6, window.innerHeight / 3)
          this.xOff = this.random(0, 1000)
          this.yOff = this.random(0, 1000)
          this.inc = 0.002
          this.graphics = new PIXI.Graphics()
          this.graphics.alpha = 0.825
          window.addEventListener(
            'resize',
            debounce(() => {
              this.bounds = this.setBounds()
            }, 250),
          )
        }

        private random(min: number, max: number) {
          return Math.random() * (max - min) + min
        }

        private map(n: number, start1: number, end1: number, start2: number, end2: number) {
          return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2
        }

        private setBounds() {
          const maxDist = window.innerWidth < 1000 ? window.innerWidth / 3 : window.innerWidth / 5
          const originX = window.innerWidth / 1.25
          const originY = window.innerWidth < 1000 ? window.innerHeight : window.innerHeight / 1.375

          return {
            x: {
              min: originX - maxDist,
              max: originX + maxDist,
            },
            y: {
              min: originY - maxDist,
              max: originY + maxDist,
            },
          }
        }

        public update() {
          const xNoise = noise2D(this.xOff, this.xOff)
          const yNoise = noise2D(this.yOff, this.yOff)
          const scaleNoise = noise2D(this.xOff, this.yOff)

          this.x = this.map(xNoise, -1, 1, this.bounds.x.min, this.bounds.x.max)
          this.y = this.map(yNoise, -1, 1, this.bounds.y.min, this.bounds.y.max)
          this.scale = this.map(scaleNoise, -1, 1, 0.5, 1)

          this.xOff += this.inc
          this.yOff += this.inc
        }

        public render() {
          this.graphics.x = this.x
          this.graphics.y = this.y
          this.graphics.scale.set(this.scale)

          this.graphics.clear()

          this.graphics.beginFill(this.fill)
          this.graphics.drawCircle(0, 0, this.radius)
          this.graphics.endFill()
        }
      }

      app.stage.filters = [new KawaseBlurFilter(30, 10, true)]

      // ColorPalette class
      class ColorPalette {
        private hue: number | undefined

        private complimentaryHue1: number | undefined

        private complimentaryHue2: number | undefined

        private saturation: number | undefined

        private lightness: number | undefined

        private baseColor: string | undefined

        private complimentaryColor1: string | undefined

        private complimentaryColor2: string | undefined

        private colorChoices: string[] | undefined

        constructor() {
          this.setColors()
          this.setCustomProperties()
        }

        private setColors() {
          this.hue = Math.floor(this.random(220, 360))
          this.complimentaryHue1 = this.hue! + 30
          this.complimentaryHue2 = this.hue! + 60
          this.saturation = 95
          this.lightness = 50
          this.baseColor = hsl(this.hue!, this.saturation!, this.lightness!)
          this.complimentaryColor1 = hsl(this.complimentaryHue1!, this.saturation!, this.lightness!)
          this.complimentaryColor2 = hsl(this.complimentaryHue2!, this.saturation!, this.lightness!)
          this.colorChoices = [this.baseColor, this.complimentaryColor1, this.complimentaryColor2]
        }

        private random(min: number, max: number) {
          return Math.random() * (max - min) + min
        }

        public randomColor() {
          if (this.colorChoices != null && this.colorChoices.length > 0) {
            const randomIndex = Math.floor(this.random(0, this.colorChoices.length))
            const color = this.colorChoices[randomIndex]
            if (color.length > 0) {
              return color.replace('#', '0x')
            }
          }
          // Valeur par défaut si colorChoices est undefined ou vide
          return '0x000000'
        }

        private setCustomProperties() {
          document.documentElement.style.setProperty('--hue', String(this.hue))
          document.documentElement.style.setProperty('--hue-complimentary1', String(this.complimentaryHue1))
          document.documentElement.style.setProperty('--hue-complimentary2', String(this.complimentaryHue2))
        }
      }

      // Create colour palette
      const colorPalette = new ColorPalette()

      // Create orbs
      const orbs: Orb[] = []

      for (let i = 0; i < 10; i++) {
        const colorByI = [
          '0xFDFE00',
          '0xfeae00',
          '0xfeae00',
          '0xFDFE00',
          '0xfeae00',
          '0xffd500',
          '0xFDFE00',
          '0xFDFE00',
          '0xffd500',
          '0xfeae00',
        ]
        const orb = new Orb(parseInt(colorByI[i], 16))
        app.stage.addChild(orb.graphics)

        orbs.push(orb)
      }

      // Render function
      const render = () => {
        orbs.forEach((orb) => {
          orb.update()
          orb.render()
        })
      }

      // Animate!
      if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        app.ticker.add(render)
      } else {
        render()
      }
    }
  }, [])

  return <canvas ref={canvasRef} className="orb-canvas" />
}

export default Background
